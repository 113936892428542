import { Box } from '@mui/material';
import ResearchCustodiansList from './ResearchCustodiansList';
import { cardContainer } from '../../../assets/styles/appStyles';
import { useState } from 'react';
import useFetchResearchCustodiansSearchResult from '../../../hooks/Locations/useFetchResearchCustodiansSearchResult';
import useUI from '../../../hooks/context/useUI';
import {
  availableColumns,
  defaultColumns,
} from '../../../settings/researchCustodianFields';
import ResearchCustodiansSelectedSearch from './ResearchCustodiansSelectedSearch';
import Styles from '../Styles/SearchCustodiansStyles';
import ResearchCustodiansFilters from './ResearchCustodiansFilters';

export default function ResearchCustodians() {
  const { searchParams, data, pageChanged, searchChanged, isLoading } =
    useFetchResearchCustodiansSearchResult();

  
  const [isSmartSearchEnabled, setIsSmartSearchEnabled] = useState<boolean>(false);
  const [searchFilters, setSearchFilters] = useState<any[]>([]);
  const setFilters = (search: any) => {
    const filters = Object.keys(search)
      .filter((element) =>
        search[element] &&
        availableColumns.findIndex((c) => c.fieldName == element) > -1
          ? true
          : false,
      )
      .map((element) => ({
        name: availableColumns.find((c) => c.fieldName == element)?.name || '',
        value: search[element],
      }));
    setSearchFilters(filters);
  };
  const handleSmartSearchToggle = (isSmartSearchEnabled: boolean) => {
    onSearchChanged(null)
    setIsSmartSearchEnabled(isSmartSearchEnabled);
    console.log('Smart search enabled:', isSmartSearchEnabled);
  };

  const onSearchChanged = (search: any) => {
    let newSearch = {
      locationName: '',
      phoneNumber: '',
      sourceLocation: '',
      address: '',
      city: '',
      state: '',
      postCode: '',
      altPhoneNumber: '',
      faxNumber: '',
      warnings: '',
      verifiedLocations: '',
      expeditors: '',
      attention: '',
      paymentForRecords: '',
      paymentTypeAccepted: '',
      receiveAuthByFax: '',
      rating: '',
      lastFulfillment: '',
      lastKnownCommunication: '',
      sourceId: '',
      smartSearch: false,
      sortProperties: [],
    };
    if (search) {
      newSearch = {
        ...search,
      };
    }
    setFilters(newSearch);
    setPageNo(0);
    searchChanged(newSearch);
  };

  const onNamePhoneChanged = (name: string, phone: string) => {
    let search;

    if (isSmartSearchEnabled) {
      search = {
        ...searchParams,
        locationName: name || '',
        phoneNumber: phone || '',
        warnings: name || '', // Example: Adjust this according to your actual logic
        smartSearch: true,
      };
    } else {
      search = {
        ...searchParams,
        locationName: name || '',
        phoneNumber: phone || '',
      };
    }

    onSearchChanged(search);
  };

  const onColumnsFilterChange = (search: any) => {
    if (!search) onSearchChanged(null);
    else {
      const newSearch = {
        ...search,
        locationName: searchParams.locationName || '',
        phoneNumber: searchParams.phoneNumber || '',
      };
      onSearchChanged(newSearch);
    }
  };
  const [pageNo, setPageNo] = useState(0);

  const handlePageChange = (newPage: number) => {
    if (pageChanged) pageChanged(newPage);
    if (setPageNo) setPageNo(newPage);
  };

  const { isSideBarOpened } = useUI();

  return (
    <Box
      sx={{ ...cardContainer, width: isSideBarOpened ? '75vw' : '90vw' }}
      p={2}
    >
      <Box mt={2} sx={Styles.greyCard} p={2}>
        <ResearchCustodiansFilters
          onSearchChanged={onNamePhoneChanged}
          isFilterCleared={searchFilters.length === 0}
          onSmartSearchToggle={handleSmartSearchToggle}
          isSmartSearchEnabled={isSmartSearchEnabled}
        />
        <ResearchCustodiansSelectedSearch
          search={searchFilters}
          onClearFilter={() => onColumnsFilterChange(null)}
        />
      </Box>
      <ResearchCustodiansList
        data={data?.custodians}
        pageNo={pageNo}
        handlePageChange={handlePageChange}
        count={data?.totalCount}
        isLoading={isLoading}
        availableColumns={availableColumns}
        defaultColumns={defaultColumns}
        onSearchChanged={onColumnsFilterChange}
        isFilterCleared={searchFilters.length === 0}
      />
    </Box>
  );
}
